@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body{
  font-family: 'Roboto', sans-serif;
}

*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.header{
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    height: 60px;
}

.title{
    font-weight: 500;
    font-size: 22px;
}

.lnrcont{
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}