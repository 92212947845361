.chatspage{
    height: calc(100vh - 60px);
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chatscontainer{
    width: 75%;
    height: 80%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 25px;
    display: flex;
}

.chatsleftside{
    border-radius: 25px 0px 0px 25px;
    height: 100%;
    width: 30%;
    /* background-color: black; */
    border-right: 1px solid rgb(208, 208, 208);
}

.userdetails{
    border-radius: 25px 0px 0px 0px;
    height: 75px;
    background-color: black;
    display: flex;
    align-items: center;
    color: white;
}

.usercircle{
    height: 45px;
    width: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;
    margin-left: 20px;
    margin-right: 10px;
}

.userdetails > span{
    font-size: 18px;
}

.chatsrightside{
    display: flex;
    flex-direction: column;
    width: 70%;

}

.chatlist{
    height: calc(100% - 75px);
    overflow-y: auto;
}

.chatbar{
    width: 100%;
    height: 75px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 0px 25px 0px 0px;
  
}

.messages{
    height: calc(100% - 140px);
    overflow-y: auto;
    padding-top: 30px;
    padding-bottom: 40px;
}

.chatinput{
    height: 65px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 100%;
    border-radius: 0px 0px 25px 0px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    justify-content: space-between;
    
}

.chatinput > input{
    border: none;
    outline: none;
    font-size: 16px;
}

.nomsg{
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;justify-content: center;
}

.nomsg > img{
    margin-top: 40px;
    height: 250px;
    width: auto;
    margin-bottom: 20px;
}